import {
  IonCol,
  IonContent,
  IonPage,
  IonRouterLink,
  IonRow,
} from "@ionic/react";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { images } from "../../assets";
import DesktopContainer from "../../components/desktop-container";
import DesktopHeader from "../../components/desktop-header";
import Header from "../../components/header";
import SideMenu from "../../components/side-menu";
import SubPageHeading from "../../components/sub-page-heading";
import { AppScreens } from "../../enums";
import "../../Global.css";
import { RootState } from "../../redux/store";
import {
  hideSideMenuDisabledItems,
  setCompetitors,
  setCustomReportParams,
  setScreen,
  setSelectedVehicle,
  showLoader,
} from "../../redux/ui/uiSlice";
import "./style.css";
import { chevronForwardCircleSharp } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { cancelGetAllData, getAllSelectedData } from "../../api/getAll";
import GlobalGrid from "../../components/globalGridMain/globalGrid";

const VehicleDetail: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();
  const history = useHistory();
  const sessionState = useSelector((state: RootState) => state.session);

  const onBackPress = useCallback(() => {
    cancelGetAllData();
    setSelectedVehicle(null);
    // setCustomReportParams(undefined)

    if (uiState.fromSearch) {
      //dispatch(setScreen(AppScreens.SearchResult));
      history.push("/Garage");
    } else {
      //dispatch(setScreen(AppScreens.Garage));
      history.push("/Garage");
    }
  }, [dispatch, uiState]);

  const onDetailedSpecs = useCallback(() => {
    //dispatch(setScreen(AppScreens.Specs));
    history.push("/Specs");
  }, [dispatch]);

  const onServiceCosts = useCallback(() => {
    //dispatch(setScreen(AppScreens.Services));
    history.push("/Services");
  }, [dispatch]);

  const onMaintananceCosts = useCallback(() => {
    //dispatch(setScreen(AppScreens.Maintenance));
    history.push("/Maintenance");
  }, [dispatch]);

  const onTCO = useCallback(() => {
    //dispatch(setScreen(AppScreens.TCO));
    history.push("/TCO");
  }, [dispatch]);

  const onPartsBasket = useCallback(() => {
    //dispatch(setScreen(AppScreens.Parts));
    history.push("/Parts");
  }, [dispatch]);

  const on10KRates = useCallback(() => {
    //dispatch(setScreen(AppScreens.Rates10K));
    history.push("/Rates10K");
  }, [dispatch]);

  const onCompetitors = useCallback(() => {
    //dispatch(setScreen(AppScreens.Competitors));
    history.push("/Competitors");
  }, [dispatch]);

  const onReviews = useCallback(() => {
    // dispatch(setScreen(AppScreens.Reviews));
    history.push("/Reviews");
  }, [dispatch]);

  const onResidualValue = useCallback(() => {
    //dispatch(setScreen(AppScreens.ResidualValue));
    history.push("/ResidualValue");
  }, [dispatch]);

  const onFinanceCalculator = useCallback(() => {
    //dispatch(setScreen(AppScreens.FinanceCalculator));
    history.push("/FinanceCalculator");
  }, [dispatch]);

  const onPreowned = useCallback(() => {
    //dispatch(setScreen(AppScreens.Preowned));
    history.push("/Preowned");
  }, [dispatch]);

  const menuLoadText = [
    "SERVICE SCHEDULES",
    "MAINTENANCE SCHEDULES",
    "TCO INFORMATION",
    "DETAILED SPECIFICATIONS",
    "PARTS BASKET",
    "10K RATES",
    "RESIDUAL VALUE",
  ];

  const gridMenuTxt = [
    {
      name: "DETAILED SPECS",
      icon: chevronForwardCircleSharp,
      func: onDetailedSpecs,
    },
    {
      name: "COMPETITORS",
      icon: chevronForwardCircleSharp,
      func: onCompetitors,
    },
    {
      name: "FINANCE CALCULATOR",
      icon: chevronForwardCircleSharp,
      func: onFinanceCalculator,
    },
    {
      name: "SERVICE COSTS",
      icon: chevronForwardCircleSharp,
      func: onServiceCosts,
    },
    {
      name: "MAINTENANCE COSTS",
      icon: chevronForwardCircleSharp,
      func: onMaintananceCosts,
    },
    {
      name: "TCO",
      icon: chevronForwardCircleSharp,
      func: onTCO,
    },
    {
      name: "PARTS BASKET",
      icon: chevronForwardCircleSharp,
      func: onPartsBasket,
    },
    {
      name: "10k RATES TABLE",
      icon: chevronForwardCircleSharp,
      func: on10KRates,
    },
    {
      name: "RESIDUAL VALUE",
      icon: chevronForwardCircleSharp,
      func: onResidualValue,
    },
    {
      name: "PRE-OWNED",
      icon: chevronForwardCircleSharp,
      func: undefined,
    },
  ];

  const [menuLoadTextIndex, setMenuLoadTextIndex] = useState(0);
  const [menuLoadTextDisplayer, setMenuLoadTextDisplayer] =
    useState(" LOADING MENUS...");

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Update the menuLoadTextDisplayer with the current menu item
      setMenuLoadTextDisplayer(menuLoadText[menuLoadTextIndex]);

      // Move to the next menu item index
      setMenuLoadTextIndex(
        (prevIndex) => (prevIndex + 1) % menuLoadText.length
      );
    }, 1000);

    // Clear the interval when the component is unmounted or dependencies change
    return () => clearInterval(intervalId);
  }, [menuLoadTextIndex, menuLoadText]);

  return (
    <GlobalGrid
      currentPageTitle="Overview"
      closeCurrentPage={() => onBackPress()}
    >
      <SubPageHeading vehicle={uiState.selectedVehicle}></SubPageHeading>
      {uiState.showSideMenuDisabledItems ? (
        <IonRow>
         {gridMenuTxt?.map((vals, ind) => {
  return (
    <IonCol key={ind} size="11" className="gridOverviewMenuRows">
      <img src={vals.icon} alt="" onClick={vals.func} />
      <p 
        onClick={vals.func} 
        style={{ 
          cursor: "pointer", 
          fontWeight: vals.name === "PRE-OWNED" ? "100" : "",
        }}
      >
        {vals.name} {"  "}
        {vals.name === "PRE-OWNED" ? (
          <span id="preOwnedTxt"> coming soon...</span>
        ) : vals.name === "RESIDUAL VALUE" ? (
          <span id="preOwnedTxt"></span>
        ) : (
          <></>
        )}
      </p>
    </IonCol>
  );
})}



        </IonRow>
      ) : (
        <IonRow className="LoadingClass">
          <div className="top loadChild">
            <div className="loadingData"></div>
          </div>
          <div className="bottom loadChild">
            <p>PLEASE GIVE US A SECOND WHILE WE FETCH UPDATED ....</p>
            <span>{menuLoadTextDisplayer}</span>
          </div>
        </IonRow>
      )}
    </GlobalGrid>
  );
};

export default VehicleDetail;
