import {
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow,
} from "@ionic/react";

import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getImage } from "../../api/mapo";
import DesktopContainer from "../../components/desktop-container";
import DesktopHeader from "../../components/desktop-header";
import DesktopVehicleLabel from "../../components/desktop-vehicle-label";
import Header from "../../components/header";
import SubPageHeading from "../../components/sub-page-heading";
import { AppScreens, Format } from "../../enums";
import "./style.css";
import { RootState } from "../../redux/store";
import {
  hideLoader,
  setCompetitors,
  setFilteredSearchVisible,
  setScreen,
  setSelectedVehicle,
  showLoader,
} from "../../redux/ui/uiSlice";
import { format } from "../../utils";

import { CompetitorData } from "../../interfaces/competitorData";
import { VehicleCustomReportParams } from "../../interfaces/customReportParams";
import { useHistory } from "react-router-dom";
import { eyeSharp, thumbsUpSharp } from "ionicons/icons";
import { icons } from "../../assets";
import { Competitor } from "../../interfaces/competitors";
import { getSelectedSpecificModelData } from "../../api/getAll";
import { SelectedVehicle } from "../../interfaces";
import { useMediaQuery } from "react-responsive";

interface CompViewProps {
  competitor: CompetitorData;
  compIndex: Number;
  Addtocart: () => void;
}


const CompetitorView: React.FC<CompViewProps> = ({
  competitor,
  compIndex, Addtocart
}: CompViewProps) => {
  const sessionState = useSelector((state: RootState) => state.session);
  const dispatch = useDispatch();
  const history = useHistory();
  const [tableView, setTableView] = useState(true);
  const [CartClicked, setIsClicked] = useState(false);
  const [addCartMsg, setAddCartMsg] = useState("");
  const [addCartAlert, setAddCartAlert] = useState(false);
  const uiState = useSelector((state: RootState) => state.ui);
  const [cartItems, setCartItems] = useState([]);
  const [duplicateItemIndex, setDuplicateItemIndex] = useState<number | null>(
    null
  );
  // useEffect(() => {
  //   const getCartFromStorage = () => {
  //     try {
  //       const storedCart = localStorage.getItem("cart");
  //       if (storedCart) {
  //         setCartItems(JSON.parse(storedCart));
  //       }
  //     } catch (error) {
  //       console.error("Error parsing cart from localStorage:", error);
  //     }
  //   };

  //   getCartFromStorage();
  // }, [cartItems]);

  const addToCart = (competitor: any) => {
    const cartItems = JSON.parse(localStorage.getItem("cart") || "[]");

    // Check if 'modelClass.result' exists and has at least one element
    if (
      competitor.modelClass &&
      competitor.modelClass.result &&
      competitor.modelClass.result.length > 0
    ) {
      // Extract specific details from the competitor object
      const {
        modelClass,
        make,
        modelCode,
        groupCode,
        variantCode,
        introDate,
        mapoCode,
        modelClassShortDescription,
        variant,
        variantId,
        status,
        model,
      } = competitor.modelClass.result[0];

      // Check if the competitor is already in the cart
      const isInCart = cartItems.some(
        (item: any) => item.modelCode === modelCode
      );

      if (!isInCart) {
        // Add the competitor details to the cart
        cartItems.push({
          modelClass,
          make,
          modelCode,
          groupCode,
          variantCode,
          introDate,
          mapoCode,
          modelClassShortDescription,
          variant,
          variantId,
          status,
          model,
        });

        // Update localStorage with the new cart items
        localStorage.setItem("cart", JSON.stringify(cartItems));

        // The console
        //console.log("Cart Data:", cartItems);

        //alert("Item added to cart!");
        setIsClicked(true);
        setTimeout(() => {
          setAddCartMsg("VEHICLE ADDED TO CART");
          setAddCartAlert(true);
          // Set another timeout after the first one has completed
          setTimeout(() => {
            setAddCartAlert(false);
          }, 3000);
        }, 500);
      } else {
        setDuplicateItemIndex(competitor);
        setTimeout(() => {
          setAddCartMsg("VEHICLE ALREADY IN CART");
          setAddCartAlert(true);
          // Set another timeout after the first one has completed
          setTimeout(() => {
            setAddCartAlert(false);
          }, 3000);
        }, 500);
      }
    } else {
      console.error("Error: No result found in competitor.modelClass object.");
    }
  };

  //The func to extract the competitors details
  function extractDetailsFromCompetitor(competitor: any) {
    if (
      competitor.modelClass &&
      competitor.modelClass.result &&
      competitor.modelClass.result.length > 0
    ) {
      // Extract specific details from the competitor object
      const {
        make,
        modelCode,
        groupCode,
        variantCode,
        introDate,
        mapoCode,
        modelClassShortDescription,
        variant,
        variantId,
        status,
        model,
      } = competitor.modelClass.result[0];

      return {
        make,
        modelCode,
        groupCode,
        variantCode,
        introDate,
        mapoCode,
        modelClassShortDescription,
        variant,
        variantId,
        status,
        model,
      };
    } else {
      // Return a default value or handle the case where the required data is not available
      return null;
    }
  }

  // Example usage:
  const competitorDetails = extractDetailsFromCompetitor(competitor);

  // Check if details are available before using them
  if (competitorDetails) {
  } else {
    // Handle the case where the required data is not available
    console.error("Competitor details not available");
  }

  const selectPrimaryVehic = useCallback(() => {
    const competitorDetails = extractDetailsFromCompetitor(competitor);

    if (competitorDetails) {
      const selectedVehicle: SelectedVehicle = {
        make: competitorDetails.make,
        model: competitorDetails.model,
        variant: competitorDetails.variant,
        g_id: competitorDetails.groupCode,
        m_id: competitorDetails.modelCode,
        v_id: competitorDetails.variantCode,
        // variant_id: v.oem_code,
      };

      dispatch(setSelectedVehicle(selectedVehicle));
      dispatch(showLoader());
      console.log("The make", selectedVehicle.g_id);
      getSelectedSpecificModelData(
        selectedVehicle.g_id,
        selectedVehicle.m_id,
        selectedVehicle.v_id,
        uiState.customReportParams.totalLifeSpan ?? 0,
        uiState.customReportParams.tyreChangeInterval ?? 0,
        uiState.customReportParams.annualMileage ?? 0
      )
        .then((allData) => {
          dispatch(
            setSelectedVehicle({ ...selectedVehicle, data: allData.selected })
          );
          dispatch(setCompetitors(allData.competition ?? []));

          dispatch(hideLoader());
          dispatch(setFilteredSearchVisible(false));
          setTimeout(() => {
            //dispatch(setScreen(AppScreens.VehicleLanding));
            history.push("/OverViewDetail");
          }, 500);
        })
        .catch((err) => {
          dispatch(hideLoader());
          alert("API Error: " + JSON.stringify(err));
        });
    } else {
      // Handle the case where the required data is not available
      console.error("Competitor details not available");
    }
  }, [dispatch, competitor, uiState, history]);

  const [condOne, setCondOne] = useState(
    competitor.competitorBase?.price_match_percentage.toFixed(2)
  );
  const [orangeClass, setOrangeClass] = useState("orangeClass");
  const [redClass, setRedClass] = useState("redClass");
  const classArray = [" ", orangeClass, redClass];


  const [localStorageLength, setLocalStorageLength] = useState(0);

  useEffect(() => {
    const storedCart = localStorage.getItem("cart");
    if (storedCart) {
      const cartItems = JSON.parse(storedCart);
      setLocalStorageLength(cartItems.length);
    }
  }, [localStorageLength, localStorage.getItem("cart")]);

  return (
    <IonCard className="gridCompetitorCard">
      <IonGrid>
        <IonRow id="compCardHeader">
          <IonCol sizeXs="6" sizeMd="4" sizeLg="4" id="gridOverviewImageCont">
            <img
              id="gridOverviewImage"
              src={getImage(
                competitor.modelSpecification.attributes.G.toString(),
                competitor.modelSpecification.attributes.M.toString(),
                competitor.modelSpecification.attributes.V.toString()
              )}
            />
          </IonCol>
          <IonCol sizeXs="6" sizeMd="8" sizeLg="8" id="gridOverviewDetails">
            <h1> {competitor.modelSpecification.attributes.make}</h1>
            <h1> {competitor.modelSpecification.attributes.model}</h1>
            <h1 id="gridOverviewVariant">
              {" "}
              {competitor.modelSpecification.attributes.variant}
            </h1>

            <span id="overViewCluster">
              <h1>
                {competitor.modelClass?.result &&
                competitor.modelClass?.result.length > 0
                  ? moment(competitor.modelClass?.result[0].introDate).format(
                      "YYYY"
                    )
                  : ""}
              </h1>
              <h1>
                G{competitor.modelSpecification.attributes.G}M
                {competitor.modelSpecification.attributes.M}V
                {competitor.modelSpecification.attributes.V}{" "}
              </h1>
              {/* <h1>
                {" "}
                {competitor.modelClass?.result &&
                competitor.modelClass?.result.length > 0
                  ? competitor.modelClass?.result[0].variantId
                  : ""}
              </h1> */}
            </span>
          </IonCol>
        </IonRow>
        <IonRow id="compDetails">
          <IonCol size="4">{""}</IonCol>
          <IonCol>{uiState.selectedVehicle?.make}</IonCol>
          <IonCol size="1">VS</IonCol>
          <IonCol>{competitor.modelSpecification.attributes.make}</IonCol>
          <IonCol>MATCH</IonCol>
        </IonRow>
        <IonRow id="compDetails">
          <IonCol size="4">LIST PRICE</IonCol>
          <IonCol>
            {" "}
            {
              uiState.selectedVehicle?.data?.modelSpecification.result
                ?.basic_specs?.base_price
            }
          </IonCol>
          <IonCol size="1"></IonCol>
          <IonCol>
            {" "}
            {competitor.modelSpecification?.result.basic_specs.base_price}
          </IonCol>
          <IonCol>
            {" "}
            {competitor.competitorBase?.price_match_percentage.toFixed(2)}%
          </IonCol>
        </IonRow>
        <IonRow id="compDetails">
          <IonCol size="4">KW</IonCol>
          <IonCol>
            {
              uiState.selectedVehicle?.data?.modelSpecification.result
                ?.performance_consumption.max_power_kw
            }
          </IonCol>
          <IonCol size="1"></IonCol>
          <IonCol>
            {
              competitor.modelSpecification?.result.performance_consumption
                .max_power_kw
            }
          </IonCol>
          <IonCol>
            {competitor.competitorBase?.kw_match_percentage.toFixed(2)}%
          </IonCol>
        </IonRow>
        <IonRow id="compDetails">
          <IonCol size="4">NM</IonCol>
          <IonCol>
            {
              uiState.selectedVehicle?.data?.modelSpecification.result
                ?.performance_consumption.torque_nm
            }
          </IonCol>
          <IonCol size="1"></IonCol>
          <IonCol>
            {
              competitor.modelSpecification?.result.performance_consumption
                .torque_nm
            }
          </IonCol>
          <IonCol>
            {competitor.competitorBase?.nm_match_percentage.toFixed(2)}%
          </IonCol>
        </IonRow>

        <IonRow id="compDetails">
          <IonCol size="4">DISPLACEMENT</IonCol>
          <IonCol>
            {
              uiState.selectedVehicle?.data?.modelSpecification.result
                ?.engine_specs?.capacity
            }
          </IonCol>
          <IonCol size="1"></IonCol>
          <IonCol>
            {competitor.modelSpecification?.result.engine_specs?.capacity}
          </IonCol>
          <IonCol>
            {competitor.competitorBase?.displacement_match_percentage.toFixed(
              2
            )}
            %
          </IonCol>
        </IonRow>

        <IonRow id="compDetails">
          <IonCol size="4">TRANSMISSION</IonCol>
          <IonCol>
            {uiState.selectedVehicle?.data?.modelSpecification.result
              ?.basic_specs.manual_or_auto === "a"
              ? "AUTO"
              : "MANUAL"}
          </IonCol>
          <IonCol size="1"></IonCol>
          <IonCol>
            {competitor.modelSpecification?.result.basic_specs
              .manual_or_auto === "a"
              ? "AUTO"
              : competitor.modelSpecification?.result.basic_specs
                  .manual_or_auto === "m"
              ? "MANUAL"
              : " "}
          </IonCol>
          <IonCol>
            {competitor.competitorBase?.transtype_match_percentage.toFixed(2)}%
          </IonCol>
        </IonRow>

        <IonRow id="compDetails">
          <IonCol size="4">FUEL TYPE</IonCol>
          <IonCol>
            {
              uiState.selectedVehicle?.data?.modelSpecification.result
                ?.basic_specs.fuel_type
            }
          </IonCol>
          <IonCol size="1"></IonCol>
          <IonCol>
            {competitor.modelSpecification?.result.basic_specs.fuel_type}
          </IonCol>
          <IonCol
            className={
              competitor?.competitorBase?.fueltype_match_percentage !==
              undefined
                ? competitor.competitorBase.fueltype_match_percentage <= 50
                  ? "redClass"
                  : competitor.competitorBase.fueltype_match_percentage < 80
                  ? "orangeClass"
                  : ""
                : ""
            }
          >
            {competitor.competitorBase?.fueltype_match_percentage !== undefined
              ? `${competitor.competitorBase.fueltype_match_percentage.toFixed(
                  2
                )}%`
              : "N/A"}
          </IonCol>
        </IonRow>

        <IonRow id="compDetails">
          <IonCol size="4">MODEL YEAR</IonCol>
          <IonCol>
            {moment(uiState.selectedVehicle?.vehicleData?.intro_date).format(
              "YYYY"
            )}
          </IonCol>
          <IonCol size="1"></IonCol>
          <IonCol>
            {moment(competitor.modelClass?.result[0].introDate).format("YYYY")}
          </IonCol>
          <IonCol>
            {competitor.competitorBase?.modelyear_match_percentage}%
          </IonCol>
        </IonRow>

        <IonRow id="compDetails">
          <IonCol size="4">MODEL CLASS</IonCol>
          <IonCol>
            {" "}
            {uiState.selectedVehicle?.data?.modelSpecification.result
              .basic_specs.model_class === "CARS - PICKUP & TRUCKS DC"
              ? " PICKUP DC"
              : uiState.selectedVehicle?.data?.modelSpecification.result
                  .basic_specs.model_class === "COMM - UTILITY VAN"
              ? "UTILITY VAN"
              : uiState.selectedVehicle?.data?.modelSpecification.result
                  .basic_specs.model_class === "CARS - SPORT UTILITY VEHICLE"
              ? "SUV"
              : uiState.selectedVehicle?.data?.modelSpecification.result
                  .basic_specs.model_class === "CARS - HATCHBACK"
              ? "HATCHBACK"
              : uiState.selectedVehicle?.data?.modelSpecification.result
                  .basic_specs.model_class === "CARS - MULTY PURPOSE VEHICLE"
              ? "MPV"
              : uiState.selectedVehicle?.data?.modelSpecification.result
                  .basic_specs.model_class === "CARS - FAMILY SEDAN"
              ? "FAMILY SEDAN"
              : uiState.selectedVehicle?.data?.modelSpecification.result
                  .basic_specs.model_class === "CARS - PICKUP & TRUCKS SC"
              ? " PICKUP SC"
              : uiState.selectedVehicle?.data?.modelSpecification.result
                  .basic_specs.model_class === "COMM - LIGHT PASSENGER"
              ? " LIGHT PASSENGER"
              : uiState.selectedVehicle?.data?.modelSpecification.result
                  .basic_specs.model_class === "CARS - SPORT WAGEN"
              ? " SPORT WAGEN"
              : uiState.selectedVehicle?.data?.modelSpecification.result
                  .basic_specs.model_class}
          </IonCol>
          <IonCol size="1"></IonCol>
          <IonCol>
            {" "}
            {competitor.modelSpecification?.result.basic_specs.model_class ===
            "CARS - PICKUP & TRUCKS DC"
              ? "PICKUP DC"
              : competitor.modelSpecification?.result.basic_specs
                  .model_class === "CARS - PICKUP & TRUCKS SC"
              ? "PICK UP SC"
              : competitor.modelSpecification?.result.basic_specs
                  .model_class === "COMM - UTILITY VAN"
              ? "UTILITY VAN"
              : competitor.modelSpecification?.result.basic_specs
                  .model_class === "CARS - SPORT UTILITY VEHICLE"
              ? "SUV"
              : competitor.modelSpecification?.result.basic_specs
                  .model_class === "CARS - FAMILY SEDAN"
              ? "FAMILY SEDAN"
              : competitor.modelSpecification?.result.basic_specs
                  .model_class === "COMM - LIGHT PASSENGER"
              ? "LIGHT PASSENGER"
              : competitor.modelSpecification?.result.basic_specs
                  .model_class === "CARS - MULTY PURPOSE VEHICLE"
              ? "MPV"
              : competitor.modelSpecification?.result.basic_specs
                  .model_class === "CARS - HATCHBACK"
              ? "HATCHBACK"
              : competitor.modelSpecification?.result.basic_specs
                  .model_class === "CARS - SPORT WAGEN"
              ? "SPORT WAGEN"
              : "Class Unknown"}
          </IonCol>
          <IonCol>
            {competitor.competitorBase?.modelclass_match_percentage.toFixed(2)}%
          </IonCol>
        </IonRow>
        <IonRow className="compAddCart">
          <IonCol size="6">
            {" "}
            <button onClick={() => selectPrimaryVehic()}>
              {" "}
              <IonIcon src={eyeSharp} />
            </button>
            <button onClick={Addtocart}>
              ADD TO CART
              <img src={icons.WhiteCartIcon} alt="tco" />
            </button>
          </IonCol>

          <IonCol size="6" className="compTotalMatch">
            <IonCol size="12">
              {competitor.competitorBase?.match_percentage} %
            </IonCol>
            <IonCol size="12">MATCH</IonCol>
          </IonCol>

          
        </IonRow>
      </IonGrid>
    </IonCard>
  );
};

export default CompetitorView;
