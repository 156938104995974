import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
} from "@ionic/react";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  LabourRates,
  VistorsParking,
  getPriceFileAge,
  manualSearch,
  vinSearch,
} from "../../api/mapo";
import { icons, images, svg } from "../../assets";
import Button from "../../components/button";
import DesktopContainer from "../../components/desktop-container";
import Header from "../../components/header";
import Loading from "../../components/loading";
import SideMenu from "../../components/side-menu";
import { AppScreens } from "../../enums";

import { Model, SelectedVehicle, Vehicle } from "../../interfaces";
import {} from "../../redux/session/sessionSlice";
import { RootState } from "../../redux/store";
import {
  hideLoader,
  setLargeHeader,
  setScreen,
  showLoader,
  setSearchKeyword,
  setSearchResults,
  setSelectedVehicle,
  setFromSearch,
  setMobileSearchType,
  setFilteredSearchVisible,
  setParameterSearchVisible,
  setLabourRatesData,
  setVistorsGarage,
  setFileAgeData,
} from "../../redux/ui/uiSlice";
import "./style.css";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { vinImageSearch } from "../../api/vision";
import DesktopSearch from "./desktop-search";
import DesktopHeader from "../../components/desktop-header";
import Heade from "../../components/heade";
import { useHistory } from "react-router-dom";
import { chevronForwardSharp, helpCircleOutline, star } from "ionicons/icons";
import RatingModal from "../../components/ratingModal";
import UserProfile from "../UserProfile";
import { useMediaQuery } from "react-responsive";
import GlobalGrid from "../../components/globalGridMain/globalGrid";

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: ReadonlyArray<string>;
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;
  prompt(): Promise<void>;
}

const Home: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const sessionState = useSelector((state: RootState) => state.session);
  const dispatch = useDispatch();
  const [vinNumber, setVinNumber] = useState("");
  const [searchText, setSearchText] = useState("");
  const [showVideo, setShowVideo] = useState(false);

  const toggleButton = () => {
    setShowVideo(!showVideo);
  };

  const handleVideoEnd = () => {
    // alert('Video has ended');
    setShowVideo(!showVideo);
  };

  const onVinSearch = useCallback(() => {
    dispatch(showLoader());
    vinSearch(vinNumber)
      .then((vinResponse) => {
        if (vinResponse.status === 200) {
          const model: Model = vinResponse.data;

          if (model && model.result?.length > 0) {
            const selectedVehicle: SelectedVehicle = {
              make: model.result[0].make,
              model: model.result[0].model,
              variant: model.result[0].variant,
              g_id: model.result[0].groupCode,
              m_id: model.result[0].modelCode,
              v_id: model.result[0].variantCode,
            };
            dispatch(setMobileSearchType("FILTERED SEARCH"));
            dispatch(setFromSearch(false));
            dispatch(setSelectedVehicle(selectedVehicle));
            dispatch(setScreen(AppScreens.VehicleLanding));
            dispatch(setLargeHeader(false));
          } else {
            dispatch(hideLoader());
            alert("No data available on this VIN number.");
          }
        } else {
          dispatch(hideLoader());
          alert(JSON.stringify(vinResponse));
        }
      })
      .catch((err) => {
        dispatch(hideLoader());
        alert(JSON.stringify(err));
      });
  }, [dispatch, vinNumber]);

  const history = useHistory();

  const onManualSearch = useCallback(() => {
    if (searchText === "") {
      alert("Search keywords are required.");
      return;
    }
    dispatch(showLoader());
    manualSearch(searchText, uiState?.isDesktop ?? false)
      .then((manResponse) => {
        dispatch(hideLoader());
        if (manResponse.status === 200) {
          const vehicles: Array<Vehicle> = manResponse.data;
          dispatch(setMobileSearchType("MANUAL SEARCH"));
          dispatch(setSearchKeyword(searchText));
          dispatch(setSearchResults(vehicles));
          dispatch(setScreen(AppScreens.SearchResult));
          dispatch(setLargeHeader(false));
        } else {
          alert(JSON.stringify(manResponse));
        }
      })
      .catch((err) => {
        dispatch(hideLoader());
        alert(JSON.stringify(err));
      });
  }, [dispatch, searchText, uiState?.isDesktop]);

  const onFilterSearch = useCallback(() => {
    dispatch(setMobileSearchType("FILTERED SEARCH"));
    dispatch(setFilteredSearchVisible(true));
  }, [dispatch]);

  const onParameterSearch = useCallback(() => {
    dispatch(setMobileSearchType("FILTERED SEARCH"));
    dispatch(setParameterSearchVisible(true));
  }, [dispatch]);

  const onViewMyGarage = useCallback(() => {
    dispatch(setLargeHeader(false));
    //dispatch(setScreen(AppScreens.Garage));
    history.push("/Garage");
    LabourRates().then((LabourRatesResponse) => {
      dispatch(setLabourRatesData(LabourRatesResponse.data?.result));
    });
    getPriceFileAge().then((priceAgeResponse) => {
      dispatch(setFileAgeData(priceAgeResponse.data));
    });
  }, [dispatch]);

  const onViewMyVin = useCallback(() => {
    dispatch(setScreen(AppScreens.VinScan));
    history.push("/VinScan");
  }, [dispatch]);

  const takePhoto = async () => {
    return Camera.getPhoto({
      resultType: CameraResultType.Base64,
      source: CameraSource.Camera,
      allowEditing: true,
      quality: 100,
      width: 800,
      preserveAspectRatio: true,
      correctOrientation: true,
    });
  };

  const b64toBlob = (b64Data: any, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const onTakePhoto = useCallback(async () => {
    takePhoto()
      .then((imagedata) => {
        dispatch(showLoader());
        const blobData = b64toBlob(imagedata.base64String, "image/png");
        vinImageSearch(blobData)
          .then((vinResponse) => {
            dispatch(hideLoader());
            let vinFound = false;
            if (vinResponse.status === 200) {
              // response[0].description
              if (vinResponse.data.length > 0) {
                const parts = vinResponse.data[0].description.split("\n");
                parts.map((part: string) => {
                  if (part.indexOf("VIN ") !== -1) {
                    setVinNumber(part.replace("VIN ", ""));
                    vinFound = true;
                    onVinSearch();
                  }
                });
              }
            }

            if (!vinFound) {
              alert(
                "Unable to detect the VIN number. Please try again with a clear shot of the VIN and adequate lighting."
              );
            }
          })
          .catch((err) => {
            dispatch(hideLoader());
            alert("E2: " + JSON.stringify(err));
          });
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.log(err);
        alert("E1: " + JSON.stringify(err));
      });
  }, [dispatch, onVinSearch]);

  useEffect(() => {
    if (!uiState.largeHeader) {
      dispatch(setLargeHeader(true));
    }
  }, [uiState?.largeHeader, dispatch]);

  //We are reloading to get any new updates that may be available
  useEffect(() => {
    const reloadCount = Number(sessionStorage.getItem("reloadCount")) || 0;

    if (reloadCount < 2) {
      sessionStorage.setItem("reloadCount", String(reloadCount + 1));
      window.location.reload();
    } else {
      //sessionStorage.removeItem("reloadCount");
    }
  }, []);

  const landingOpts = [
    "Use the VIN SCAN feature to scan your license disk, we then cross reference the number with our database to accurately ideantify your vehcle",
    "Use the FILTERED SEARCH feature to discover a model that aligns with your requirements by specifying parameters that will narrow down the options.",
    "Use the VIEW GARAGE feature to view all the active models , that you have purchase and access all the data available for the vehicle.",
  ];

  const [showInstallButton, setShowInstallButton] = useState<boolean>(false);
  const [deferredPrompt, setDeferredPrompt] =
    useState<BeforeInstallPromptEvent | null>(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event: Event) => {
      const beforeInstallPromptEvent = event as BeforeInstallPromptEvent;
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      // event.preventDefault();
      // Stash the event so it can be triggered later.
      setDeferredPrompt(beforeInstallPromptEvent);
      // Show the install button
      setShowInstallButton(true);
    };

    window.addEventListener(
      "beforeinstallprompt",
      handleBeforeInstallPrompt as EventListener
    );

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt as EventListener
      );
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      // Show the install prompt
      deferredPrompt.prompt();

      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
          // Reset the deferredPrompt value
          setDeferredPrompt(null);
          // Hide the install button
          setShowInstallButton(false);
        }
      });
    }
  };

  return (
    <GlobalGrid
      currentPageTitle={"MODEL SEARCH"}
      videoSource="../Home/APP-Home.mp4"
    >
      <IonRow id="cardsRow">
        <IonCol
          id="cards"
          size="12"
          sizeXs="12"
          sizeMd="12"
          sizeLg="10"
          sizeXl="3.5"
        >
          <div
            className="box"
            onClick={() => {
              onViewMyVin();
            }}
          >
            <div className="innerBox">
              <IonCol size="3.6" className="top">
                <img
                  src={icons.vinSearch}
                  className="img"
                  alt="Camera Scan"
                  onClick={() => {
                    onViewMyVin();
                  }}
                />
              </IonCol>

              <IonCol size="8.4" className="bottom">
                <IonText className="cardTitle">Vin Scan</IonText>
                Use the VIN SCAN feature to scan your license disk, we then
                cross reference the number with our database to accurately
                ideantify your vehcle
              </IonCol>
            </div>
          </div>
        </IonCol>{" "}
        <IonCol
          id="cards"
          size="12"
          sizeXs="12"
          sizeMd="12"
          sizeLg="10"
          sizeXl="4"
        >
          <div
            className="box"
            onClick={() => {
              onFilterSearch();
            }}
          >
            <div className="innerBox">
              <div className="top">
                <img
                  src={icons.filterSearch}
                  className="img"
                  alt="Camera Scan"
                  onClick={() => {
                    onFilterSearch();
                  }}
                />
              </div>
              <IonText className="bottom">
                <IonText className="cardTitle">Filtered Search</IonText>
                Use the FILTERED SEARCH feature to discover a model that aligns
                with your requirements by specifying parameters that will narrow
                down the options.
              </IonText>
            </div>
          </div>
        </IonCol>
        <IonCol
          id="cards"
          size="12"
          sizeXs="12"
          sizeMd="12"
          sizeLg="10"
          sizeXl="3.5"
        >
          <div
            className="box"
            onClick={() => {
              onViewMyGarage();
            }}
          >
            <div className="innerBox">
              <div className="top">
                <img
                  src={icons.myGarageSearch}
                  className="img"
                  alt="Camera Scan"
                />
              </div>
              <IonText className="bottom">
                <IonText className="cardTitle">My Garage</IonText>
                Use the VIEW GARAGE feature to view all the active models , that
                you have purchase and access all the data available for the
                vehicle.
              </IonText>
            </div>
          </div>
        </IonCol>
        <IonCol
          size="12"
          sizeXs="12"
          sizeMd="12"
          sizeLg="12"
          sizeXl="12"
          id="gridInstallBtn"
        >
          {showInstallButton && (
            <button className="installButton" onClick={handleInstallClick}>
              Install App
            </button>
          )}
        </IonCol>
      </IonRow>
    </GlobalGrid>
  );
};

export default Home;
