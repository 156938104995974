import {
  IonAccordion,
  IonAccordionGroup,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonText,
} from "@ionic/react";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  StartGetAllData,
  getAllSelectedData,
  getSelectedSpecificModelData,
} from "../../api/getAll";
import {
  deleteVehicle,
  getGarage,
  getImage,
  modelClass,
  manualSearch,
  VistorsParking,
  BasketLayout,
  dataCompleteness,
  modelSpecificationDetails,
  getPriceFileAge,
} from "../../api/mapo";
import { icons, images } from "../../assets";
import DesktopContainer from "../../components/desktop-container";
import DesktopHeader from "../../components/desktop-header";
import Header from "../../components/header";
import Loading from "../../components/loading";
import SideMenu from "../../components/side-menu";
import { Modal, Button } from "react-bootstrap";
import "../../Global.css";
import { DataCompleteness, SelectedVehicle, Vehicle } from "../../interfaces";
import { VehicleCustomReportParams } from "../../interfaces/customReportParams";
import { ModelClass } from "../../interfaces/modelClass";
import { RootState } from "../../redux/store";
import {
  hideLoader,
  hideSideMenuDisabledItems,
  setBasketLayout,
  setCompetitors,
  setCustomReportParams,
  setFileAgeData,
  setMenuCollapsed,
  setScreen,
  setSelectedVehicle,
  setVistorsGarage,
  showLoader,
  showSideMenuDisabledItems,
} from "../../redux/ui/uiSlice";
import { decVal } from "../../utils";
import "./style.css";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
} from "@ionic/react";
import {
  chevronBackCircleOutline,
  chevronBackOutline,
  volumeHighOutline,
  trash,
  volumeLowOutline,
  flag,
  caretUpOutline,
  caretDownOutline,
  chevronBackCircleSharp,
  chevronUpCircle,
  chevronDownCircle,
  chevronForwardCircle,
  eyeSharp,
  thumbsUpSharp,
} from "ionicons/icons";
// import GarageVideo from "../../assets/mobile/images/"
import { vistorsParking } from "../../interfaces/VistorsParking";
import SubPageHeading from "../../components/sub-page-heading";
import { useHistory } from "react-router-dom";
import { ModelSpecification } from "../../interfaces/modelSpecification";
import CountdownTimer from "../../components/garageCountDown/garageCountDown";
import { useMediaQuery } from "react-responsive";
import GlobalGrid from "../../components/globalGridMain/globalGrid";
import GlobalCar from "../../components/visitorsParking/visitorParking";
import { brotliDecompress } from "zlib";

interface DataType {
  serviceChecks: number;
  serviceChecks_Max: number;
  serviceLabour: number;
  serviceLabour_Max: number;
  serviceLubes: number;
  serviceLubes_Max: number;
  serviceParts: number;
  serviceParts_Max: number;
  maintenanceChecks: number;
  maintenanceChecks_Max: number;
  maintenanceLabour: number;
  maintenanceLabour_Max: number;
  maintenanceParts: number;
  maintenanceParts_Max: number;
  gCode: number;
  mCode: number;
  vCode: number;
}
interface GarageItem {
  g_id: number;
  m_id: number;
  v_id: number;
  end_date: string;
  // Add other properties as needed
}

const Garage: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const sessionState = useSelector((state: RootState) => state.session);
  const dispatch = useDispatch();
  const history = useHistory();
  const [addCartAlert, setAddCartAlert] = useState(false);
  const [addCartMsg, setAddCartMsg] = useState("");
  const [CartClicked, setIsClicked] = useState(false);
  const [garageData, setGarageData] = useState<Array<ModelClass> | undefined>();
  const [showCircles, setShowCircles] = useState<Array<boolean | undefined>>(
    []
  );
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedVehicleIndex, setSelectedVehicleIndex] = useState<
    number | null
  >(null);
  const [completeness, setCompleteness] = useState<DataType | undefined>(
    undefined
  );

  const [modelSpecification, setModelSpecification] = useState<
    ModelSpecification | undefined
  >();

  const [garageController, setGarageController] = useState(String);

  const onSelectVehicle = useCallback(
    (modelClass: ModelClass) => {
      StartGetAllData();
      dispatch(showLoader());

      const gmvCode =
        "G" +
        modelClass.result[0].groupCode +
        "M" +
        modelClass.result[0].modelCode +
        "V" +
        modelClass.result[0].variantCode;

      manualSearch(gmvCode, uiState.isDesktop ?? false)
        .then((searchResult) => {
          if (searchResult.status === 200) {
            const searchResults: Array<Vehicle> = searchResult.data;

            getAllSelectedData(
              searchResults[0].g_id,
              searchResults[0].m_id,
              searchResults[0].v_id,
              uiState.customReportParams.totalLifeSpan ?? 0,
              uiState.customReportParams.tyreChangeInterval ?? 0,
              uiState.customReportParams.annualMileage ?? 0,
              uiState.customReportParams.deposit ?? 0,
              uiState.customReportParams.interest ?? 0,
              uiState.customReportParams.term ?? 0,
              uiState.customReportParams.residual ?? 0
            )
              .then((allData) => {
                dispatch(
                  setSelectedVehicle({
                    ...searchResults[0],
                    make: searchResults[0].g_desc,
                    model: searchResults[0].m_desc,
                    data: allData.selected,
                    vehicleData: searchResults[0],
                  })
                );
                // dispatch(setCompetitors(allData.competitors ?? []));

                const selectedVehicleCustomParams: VehicleCustomReportParams = {
                  retailprice: decVal(
                    allData.selected?.modelSpecification.result?.basic_specs
                      ?.base_price
                  ),
                  co2tax: Number(
                    allData.selected?.modelSpecification.result?.engine_specs
                      .co2 ?? ""
                  ),

                  // Service
                  servicestandardkm: Number(
                    allData.selected?.modelSpecification.result
                      ?.service_maintenance_warranty.service_plan ?? "0"
                  ),
                  servicestandardmnt: Number(
                    allData.selected?.modelSpecification.result
                      ?.service_maintenance_warranty.service_plan_months ?? "0"
                  ),

                  // Maintenance
                  maintenancestandardkm: Number(
                    allData.selected?.modelSpecification.result
                      ?.service_maintenance_warranty.maintenance_plan ?? "0"
                  ),
                  maintenancestandardmnt: Number(
                    allData.selected?.modelSpecification.result
                      ?.service_maintenance_warranty.maintenance_plan_months ??
                      "0"
                  ),

                  // baloonpayment: 0,
                };

                dispatch(
                  setCustomReportParams({
                    ...uiState.customReportParams,
                    selectedVehicleCustomParams: selectedVehicleCustomParams,
                  })
                );

                dispatch(hideLoader());

                dispatch(showSideMenuDisabledItems());
              })
              .catch((err) => {
                dispatch(hideLoader());
                console.log("API Error 1: " + JSON.stringify(err));
                //alert("API Error 1: " + JSON.stringify(err));
              });
          } else {
            dispatch(hideLoader());
          }

          dispatch(hideSideMenuDisabledItems());
        })
        .catch((err) => {
          dispatch(hideLoader());
          console.log("API Error 2: " + JSON.stringify(err));
          //alert("API Error 2: " + JSON.stringify(err));
        });
    },
    [dispatch, uiState.customReportParams, uiState.isDesktop]
  );

  const onSelectVehicleGarage = useCallback(
    (modelClass: ModelClass) => {
      StartGetAllData();
      dispatch(showLoader());
      getPriceFileAge().then((priceAgeResponse) => {
        dispatch(setFileAgeData(priceAgeResponse.data));
      });
      const gmvCode =
        "G" +
        modelClass.result[0].groupCode +
        "M" +
        modelClass.result[0].modelCode +
        "V" +
        modelClass.result[0].variantCode;

      manualSearch(gmvCode, uiState.isDesktop ?? false)
        .then((searchResult) => {
          if (searchResult.status === 200) {
            const searchResults: Array<Vehicle> = searchResult.data;

            getSelectedSpecificModelData(
              searchResults[0].g_id,
              searchResults[0].m_id,
              searchResults[0].v_id,
              uiState.customReportParams.totalLifeSpan ?? 0,
              uiState.customReportParams.tyreChangeInterval ?? 0,
              uiState.customReportParams.annualMileage ?? 0
            )
              .then((allData) => {
                dispatch(
                  setSelectedVehicle({
                    ...searchResults[0],
                    make: searchResults[0].g_desc,
                    model: searchResults[0].m_desc,
                    data: allData.selected,
                    vehicleData: searchResults[0],
                  })
                );
                StartGetAllData();
                dispatch(setCompetitors(allData.competition ?? []));

                dispatch(hideLoader());
                //dispatch(setScreen(AppScreens.VehicleDetail));
                history.push("/VehicleDetail");
              })
              .catch((err) => {
                dispatch(hideLoader());
                // alert("API Error 1: " + JSON.stringify(err));
              });
          } else {
            dispatch(hideLoader());
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          //alert("API Error 2: " + JSON.stringify(err));
        });
    },
    [dispatch, uiState.customReportParams, uiState.isDesktop]
  );

  const onSelectVehicleGarageLimeted = useCallback(
    
    (modelClass: ModelClass) => {
      StartGetAllData();
      getPriceFileAge().then((priceAgeResponse) => {
        dispatch(setFileAgeData(priceAgeResponse.data));
      });
      dispatch(showLoader());

      const gmvCode =
        "G" +
        modelClass.result[0].groupCode +
        "M" +
        modelClass.result[0].modelCode +
        "V" +
        modelClass.result[0].variantCode;

      manualSearch(gmvCode, uiState.isDesktop ?? false)
        .then((searchResult) => {
          if (searchResult.status === 200) {
            const searchResults: Array<Vehicle> = searchResult.data;
           
            getSelectedSpecificModelData(
              searchResults[0].g_id,
              searchResults[0].m_id,
              searchResults[0].v_id,
              uiState.customReportParams.totalLifeSpan ?? 0,
              uiState.customReportParams.tyreChangeInterval ?? 0,
              uiState.customReportParams.annualMileage ?? 0
            )
              .then((allData) => {
                dispatch(
                  setSelectedVehicle({
                    ...searchResults[0],
                    make: searchResults[0].g_desc,
                    model: searchResults[0].m_desc,
                    data: allData.selected,
                    vehicleData: searchResults[0],
                  })
                );
                StartGetAllData();
                dispatch(setCompetitors(allData.competition ?? []));
                
                dispatch(hideLoader());
                //dispatch(setScreen(AppScreens.VehicleDetail));
                history.push("/OverViewDetail");
              })
              .catch((err) => {
                dispatch(hideLoader());
                // alert("API Error 1: " + JSON.stringify(err));
              });
          } else {
            dispatch(hideLoader());
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          //alert("API Error 2: " + JSON.stringify(err));
        });
    },
    [dispatch, uiState.customReportParams, uiState.isDesktop]
  );

  const [localStorageLength, setLocalStorageLength] = useState(0);

  useEffect(() => {
    const storedCart = localStorage.getItem("cart");
    if (storedCart) {
      const cartItems = JSON.parse(storedCart);
      setLocalStorageLength(cartItems.length);
    }

    VistorsParking()
      .then((VistorsParkingRespose) => {
        dispatch(setVistorsGarage(VistorsParkingRespose.data));
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.log(err);
        alert(JSON.stringify(err));
      });
  }, [localStorageLength]);

  const [duplicateItemIndex, setDuplicateItemIndex] = useState<number | null>(
    null
  );

  const HandleAddToCarts = (row: any, index: any) => {
    // Retrieve existing cart data from localStorage
    const storedCartString = localStorage.getItem("cart");
    const existingCart = storedCartString ? JSON.parse(storedCartString) : [];

    // Check if the item with the same 'MapoCode' is already in the cart
    const isDuplicate = existingCart.some(
      (item: any) => item.mapoCode === row[0].mapoCode
    );

    if (!isDuplicate) {
      // Extracting gCode, mCode, and vCode from garageDate
      const {
        g_id: gCode,
        m_id: mCode,
        v_id: vCode,
      } = garageDate?.find((data) => {
        return (
          data.g_id === row[0].groupCode &&
          data.m_id === row[0].modelCode &&
          data.v_id === row[0].variantCode
        );
      }) || {};

      const newItem = {
        ...row[0],
        gCode: gCode,
        mCode: mCode,
        vCode: vCode,
      };

      existingCart.push(newItem);

      localStorage.setItem("cart", JSON.stringify(existingCart));

      setLocalStorageLength((prevLength) => prevLength + 1);

      setIsClicked(true);
      setTimeout(() => {
        setAddCartMsg("VEHICLE ADDED TO CART");
        setAddCartAlert(true);

        setTimeout(() => {
          setAddCartAlert(false);
        }, 3000);
      }, 500);
    } else {
      setDuplicateItemIndex(index);
      setTimeout(() => {
        setAddCartMsg("VEHICLE ALREADY IN CART");
        setAddCartAlert(true);

        setTimeout(() => {
          setAddCartAlert(false);
        }, 3000);
      }, 500);
    }
  };

  const selectPrimaryVehicle = useCallback(
    (v: Vehicle) => {
      const selectedVehicle: SelectedVehicle = {
        make: v.g_desc,
        model: v.m_desc,
        variant: v.v_desc,
        g_id: v.g_id,
        m_id: v.m_id,
        v_id: v.v_id,
        variant_id: v.oem_code,
      };
      dispatch(setSelectedVehicle(selectedVehicle));
      dispatch(showLoader());

      getAllSelectedData(
        selectedVehicle.g_id,
        selectedVehicle.m_id,
        selectedVehicle.v_id,
        uiState.customReportParams.totalLifeSpan ?? 0,
        uiState.customReportParams.tyreChangeInterval ?? 0,
        uiState.customReportParams.annualMileage ?? 0,
        uiState.customReportParams.deposit ?? 0,
        uiState.customReportParams.interest ?? 0,
        uiState.customReportParams.term ?? 0,
        uiState.customReportParams.residual ?? 0
      )
        .then((allData) => {
          dispatch(
            setSelectedVehicle({ ...selectedVehicle, data: allData.selected })
          );
          dispatch(setCompetitors(allData.competitors ?? []));

          const selectedVehicleCustomParams: VehicleCustomReportParams = {
            retailprice: decVal(
              allData.selected?.modelSpecification.result?.basic_specs
                ?.base_price
            ),
            co2tax: Number(
              allData.selected?.modelSpecification.result?.engine_specs.co2 ??
                ""
            ),

            // Service
            servicestandardkm: Number(
              allData.selected?.modelSpecification.result
                ?.service_maintenance_warranty.service_plan ?? "0"
            ),
            servicestandardmnt: Number(
              allData.selected?.modelSpecification.result
                ?.service_maintenance_warranty.service_plan_months ?? "0"
            ),

            // Maintenance
            maintenancestandardkm: Number(
              allData.selected?.modelSpecification.result
                ?.service_maintenance_warranty.maintenance_plan ?? "0"
            ),
            maintenancestandardmnt: Number(
              allData.selected?.modelSpecification.result
                ?.service_maintenance_warranty.maintenance_plan_months ?? "0"
            ),

            // baloonpayment: 0,
          };

          const competitorCustomParams: Array<VehicleCustomReportParams> = [];

          allData.competitors?.map((competitor) => {
            const competitorCustomParam: VehicleCustomReportParams = {
              retailprice: decVal(
                competitor.modelSpecification.result?.basic_specs?.base_price
              ),
              co2tax: Number(
                competitor.modelSpecification.result?.engine_specs.co2 ?? ""
              ),

              // Service
              servicestandardkm: Number(
                competitor.modelSpecification.result
                  ?.service_maintenance_warranty.service_plan ?? "0"
              ),
              servicestandardmnt: Number(
                competitor.modelSpecification.result
                  ?.service_maintenance_warranty.service_plan_months ?? "0"
              ),

              // Maintenance
              maintenancestandardkm: Number(
                competitor.modelSpecification.result
                  ?.service_maintenance_warranty.maintenance_plan ?? "0"
              ),
              maintenancestandardmnt: Number(
                competitor.modelSpecification.result
                  ?.service_maintenance_warranty.maintenance_plan_months ?? "0"
              ),

              baloonpayment: 0,
            };
            competitorCustomParams.push(competitorCustomParam);
          });

          dispatch(
            setCustomReportParams({
              ...uiState.customReportParams,
              selectedVehicleCustomParams: selectedVehicleCustomParams,
              competitorCustomParams: competitorCustomParams,
            })
          );

          dispatch(hideLoader());

          //dispatch(setScreen(AppScreens.VehicleLanding));
          // setTimeout(() => {
          //   dispatch(setScreen(AppScreens.VehicleLanding));
          // }, 500);
        })
        .catch((err) => {
          dispatch(hideLoader());
          alert("API Error: " + JSON.stringify(err));
        });
    },
    [dispatch, uiState.customReportParams]
  );

  const onSelectVehicleAllParking = useCallback(
    (modelClass: vistorsParking) => {
      StartGetAllData();
      dispatch(showLoader());

      const gmvCode =
        "G" + modelClass.g_id + "M" + modelClass.m_id + "V" + modelClass.v_id;
     
      manualSearch(gmvCode, uiState.isDesktop ?? false)
        .then((searchResult) => {
          if (searchResult.status === 200) {
            const searchResults: Array<Vehicle> = searchResult.data;

            getAllSelectedData(
              searchResults[0].g_id,
              searchResults[0].m_id,
              searchResults[0].v_id,
              uiState.customReportParams.totalLifeSpan ?? 0,
              uiState.customReportParams.tyreChangeInterval ?? 0,
              uiState.customReportParams.annualMileage ?? 0,
              uiState.customReportParams.deposit ?? 0,
              uiState.customReportParams.interest ?? 0,
              uiState.customReportParams.term ?? 0,
              uiState.customReportParams.residual ?? 0
            )
              .then((allData) => {
                dispatch(
                  setSelectedVehicle({
                    ...searchResults[0],
                    make: searchResults[0].g_desc,
                    model: searchResults[0].m_desc,
                    data: allData.selected,
                    vehicleData: searchResults[0],
                  })
                );
                //dispatch(setCompetitors(allData.competitors ?? []));

                const selectedVehicleCustomParams: VehicleCustomReportParams = {
                  retailprice: decVal(
                    allData.selected?.modelSpecification.result?.basic_specs
                      ?.base_price
                  ),
                  co2tax: Number(
                    allData.selected?.modelSpecification.result?.engine_specs
                      .co2 ?? ""
                  ),

                  // Service
                  servicestandardkm: Number(
                    allData.selected?.modelSpecification.result
                      ?.service_maintenance_warranty.service_plan ?? "0"
                  ),
                  servicestandardmnt: Number(
                    allData.selected?.modelSpecification.result
                      ?.service_maintenance_warranty.service_plan_months ?? "0"
                  ),

                  // Maintenance
                  maintenancestandardkm: Number(
                    allData.selected?.modelSpecification.result
                      ?.service_maintenance_warranty.maintenance_plan ?? "0"
                  ),
                  maintenancestandardmnt: Number(
                    allData.selected?.modelSpecification.result
                      ?.service_maintenance_warranty.maintenance_plan_months ??
                      "0"
                  ),

                  // baloonpayment: 0,
                };

                // const competitorCustomParams: Array<VehicleCustomReportParams> =
                //   [];

                // allData.competitors?.map((competitor) => {
                //   const competitorCustomParam: VehicleCustomReportParams = {
                //     retailprice: decVal(
                //       competitor.modelSpecification.result?.basic_specs
                //         ?.base_price
                //     ),
                //     co2tax: Number(
                //       competitor.modelSpecification.result?.engine_specs.co2 ??
                //         ""
                //     ),

                //     // Service
                //     servicestandardkm: Number(
                //       competitor.modelSpecification.result
                //         ?.service_maintenance_warranty.service_plan ?? "0"
                //     ),
                //     servicestandardmnt: Number(
                //       competitor.modelSpecification.result
                //         ?.service_maintenance_warranty.service_plan_months ??
                //         "0"
                //     ),

                //     // Maintenance
                //     maintenancestandardkm: Number(
                //       competitor.modelSpecification.result
                //         ?.service_maintenance_warranty.maintenance_plan ?? "0"
                //     ),
                //     maintenancestandardmnt: Number(
                //       competitor.modelSpecification.result
                //         ?.service_maintenance_warranty
                //         .maintenance_plan_months ?? "0"
                //     ),

                //     baloonpayment: 0,
                //   };
                //   competitorCustomParams.push(competitorCustomParam);
                // });

                dispatch(
                  setCustomReportParams({
                    ...uiState.customReportParams,
                    selectedVehicleCustomParams: selectedVehicleCustomParams,
                    //competitorCustomParams: competitorCustomParams,
                  })
                );

                dispatch(hideLoader());
                //dispatch(setScreen(AppScreens.VehicleDetail));
                dispatch(showSideMenuDisabledItems());
              })
              .catch((err) => {
                dispatch(hideLoader());
                alert("API Error 1: " + JSON.stringify(err));
              });
          } else {
            dispatch(hideLoader());
          }

          dispatch(hideSideMenuDisabledItems());
        })
        .catch((err) => {
          dispatch(hideLoader());
          alert("API Error 2: " + JSON.stringify(err));
        });
    },
    [dispatch, uiState.customReportParams, uiState.isDesktop]
  );
  const onSelectVehicleParking = useCallback(
    (modelClass: vistorsParking) => {
      dispatch(showLoader());

      const gmvCode =
        "G" + modelClass.g_id + "M" + modelClass.m_id + "V" + modelClass.v_id;

      manualSearch(gmvCode, uiState.isDesktop ?? false)
        .then((searchResult) => {
          if (searchResult.status === 200) {
            const searchResults: Array<Vehicle> = searchResult.data;

            getSelectedSpecificModelData(
              searchResults[0].g_id,
              searchResults[0].m_id,
              searchResults[0].v_id,
              uiState.customReportParams.totalLifeSpan ?? 0,
              uiState.customReportParams.tyreChangeInterval ?? 0,
              uiState.customReportParams.annualMileage ?? 0
            )
              .then((allData) => {
                dispatch(
                  setSelectedVehicle({
                    ...searchResults[0],
                    make: searchResults[0].g_desc,
                    model: searchResults[0].m_desc,
                    data: allData.selected,
                    vehicleData: searchResults[0],
                  })
                );
                dispatch(setCompetitors(allData.competition ?? []));

                dispatch(hideLoader());
                // dispatch(setScreen(AppScreens.VehicleDetail));
                history.push("/VehicleDetail");
              })
              .catch((err) => {
                dispatch(hideLoader());
                alert("API Error 1: " + JSON.stringify(err));
              });
          } else {
            dispatch(hideLoader());
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          alert("API Error 2: " + JSON.stringify(err));
        });
    },
    [dispatch, uiState.customReportParams, uiState.isDesktop]
  );

  const [garageDate, setGarageDate] = useState<GarageItem[] | undefined>(
    undefined
  );

  useEffect(() => {
    if (garageData === undefined) {
      dispatch(showLoader());

      // BasketLayout().then((BasketLayoutResponse) => {
      //   dispatch(setBasketLayout(BasketLayoutResponse.data?.result));
      //   console.log("Basket", BasketLayoutResponse);
      // });

      VistorsParking()
        .then((VistorsParkingRespose) => {
          dispatch(setVistorsGarage(VistorsParkingRespose.data));
        })
        .catch((err) => {
          dispatch(hideLoader());
          console.log(err);
          alert(JSON.stringify(err));
        });

      getGarage(sessionState.user?.username ?? "")
        .then((getGarageResponse) => {
          if (getGarageResponse.status === 200) {
            const newList: Array<ModelClass> = [];
            console.log("The garage response");
            setGarageDate(getGarageResponse.data);

            if (getGarageResponse.data.length === 0) {
              dispatch(hideLoader());
              setGarageData([]);
            } else {
              const filteredGarageItems = getGarageResponse.data.reduce(
                (acc: any, currentItem: any) => {
                  // Check if there's already an item with the same combination of g_id, m_id, and v_id
                  const existingItem = acc.find(
                    (item: any) =>
                      item.g_id === currentItem.g_id &&
                      item.m_id === currentItem.m_id &&
                      item.v_id === currentItem.v_id
                  );

                  // If there's no existing item or if the current item's end_date is not null and is greater than the current date
                  if (
                    !existingItem ||
                    (currentItem.end_date !== null &&
                      new Date(currentItem.end_date) > new Date())
                  ) {
                    // If there's an existing item and it's expired, remove it
                    if (
                      existingItem &&
                      existingItem.end_date !== null &&
                      new Date(existingItem.end_date) <= new Date()
                    ) {
                      acc.splice(acc.indexOf(existingItem), 1);
                    }
                    // Add the current item to the list
                    acc.push(currentItem);
                  }

                  return acc;
                },
                []
              );

              if (filteredGarageItems.length === 0) {
                dispatch(hideLoader());
                setGarageData([]);
              } else {
                filteredGarageItems.forEach((garageItem: any) => {
                  modelClass(garageItem.g_id, garageItem.m_id, garageItem.v_id)
                    .then((modelClassResponse) => {
                      if (modelClassResponse.status === 200) {
                        newList.push(modelClassResponse.data);
                        if (newList.length === filteredGarageItems.length) {
                          dispatch(hideLoader());
                          setGarageData(newList);
                        }
                      } else {
                        dispatch(hideLoader());
                        alert(modelClassResponse.statusText);
                        return;
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      dispatch(hideLoader());
                      alert(JSON.stringify(err));
                    });
                });
              }
            }
          } else {
            dispatch(hideLoader());
            alert(getGarageResponse.statusText);
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          alert(JSON.stringify(err));
        });
    }
  }, [garageData, dispatch, sessionState.user]);

  const onDeleteVehicle = useCallback(
    (modelClass: ModelClass) => {
      dispatch(showLoader());
      deleteVehicle(
        sessionState.user?.username ?? "",
        modelClass.result[0].groupCode,
        modelClass.result[0].modelCode,
        modelClass.result[0].variantCode
      )
        .then(() => {
          setGarageData(undefined);
        })
        .catch((err) => {
          dispatch(hideLoader());
          alert(JSON.stringify(err));
        });
    },
    [sessionState.user, dispatch]
  );

  const onRemoveToCartVehicle = useCallback(
    (modelClass: ModelClass) => {
      dispatch(showLoader());
      deleteVehicle(
        sessionState.user?.username ?? "",
        modelClass.result[0].groupCode,
        modelClass.result[0].modelCode,
        modelClass.result[0].variantCode
      )
        .then(() => {
          setGarageData(undefined);
        })
        .catch((err) => {
          dispatch(hideLoader());
        });
    },
    [sessionState.user, dispatch]
  );

  const navigateToHome = () => {
    history.push("/home");
  };

  const [showVideo, setShowVideo] = useState(false);

  const toggleButton = () => {
    setShowVideo(!showVideo);
  };

  const handleVideoEnd = () => {
    setShowVideo(!showVideo);
  };

  const [showVisitor, setShowVisitor] = useState(false);
  const [seenBy, setSeenBy] = useState(0);
  const [prevSeen, setPrevSeen] = useState(Number);

  const [dataArray, setDataArray] = useState<DataType[]>([]);

  useEffect(() => {
    if (!garageData) {
      return;
    }

    garageData.forEach(async (result) => {
      if (completeness === undefined) {
        dispatch(showLoader());

        try {
          const dataCompletenessResponse = await dataCompleteness(
            result.result[0].groupCode,
            result.result[0].modelCode,
            result.result[0].variantCode
          );

          if (dataCompletenessResponse.status === 200) {
            const data = dataCompletenessResponse.data;

            setDataArray((prevDataArray) => [...prevDataArray, data]);

            setCompleteness(data);
          } else {
            console.error(dataCompletenessResponse.statusText);
          }
        } catch (err) {
          console.error(err);
        } finally {
          dispatch(hideLoader());
        }
      }
    });

    uiState.VistorsParking?.forEach(async (vistors) => {
      if (completeness === undefined) {
        dispatch(showLoader());

        try {
          const dataCompletenessResponse = await dataCompleteness(
            vistors.g_id,
            vistors.m_id,
            vistors.v_id
          );

          if (dataCompletenessResponse.status === 200) {
            const data = dataCompletenessResponse.data;

            setDataArray((prevDataArray) => [...prevDataArray, data]);

            setCompleteness(data);
          } else {
            console.error(dataCompletenessResponse.statusText);
          }
        } catch (err) {
          console.error(err);
        } finally {
          dispatch(hideLoader());
        }
      }
    });
  }, [completeness, dispatch, garageData, uiState.VistorsParking]);

  const targetDate = new Date("2024-02-29T23:59:59");

  return (
    <IonPage>
      {showVisitor ? (
        <GlobalGrid
          currentPageTitle="VISITORS PARKING"
          closeCurrentPage={() => setShowVisitor(!showVisitor)}
        >
          <div id="visitorCont">
            {uiState.VistorsParking?.map((result, index) => {
              const onSelectVehicleGarageLimited = () => {};
              const HandleAddToCart = () => {};
              const vehicleShowCircles = showCircles[index] || false;

              return (
                <>
                  <div className="visitorCard ">
                    <IonCol size="12" className="vistorImgCont">
                      <IonCol size="6" id="img">
                        <img
                          onClick={() => {
                            onSelectVehicleParking(result);
                            onSelectVehicleAllParking(result);
                          }}
                          src={getImage(
                            result.g_id.toString(),
                            result.m_id.toString(),
                            result.v_id.toString()
                          )}
                          alt=""
                        />
                      </IonCol>
                      <IonCol size="6" id="details">
                        <IonCol size="12" id="row">
                          {result.g_desc}
                        </IonCol>
                        <IonCol size="12" id="row">
                          {result.m_desc.slice(0, 25)}
                        </IonCol>
                        <IonCol size="12" id="row">
                          {result.v_desc.slice(0, 15)}
                        </IonCol>
                        <IonCol size="12" id="row">
                          {" "}
                          G{result.g_id}M{result.m_id}V{result.v_id}
                        </IonCol>
                      </IonCol>
                    </IonCol>
                    <IonCol size="12" className="comprehensiveDropDown">
                      <IonCol size="11">Comprehensive data available</IonCol>
                      <IonCol size="1">
                        <IonIcon
                          id="dropDownToggler"
                          onClick={() => {
                            const updatedShowCircles = [...showCircles];
                            updatedShowCircles[index] = !vehicleShowCircles;
                            setShowCircles(updatedShowCircles);
                            setSelectedVehicleIndex(index);
                          }}
                          src={
                            vehicleShowCircles
                              ? chevronUpCircle
                              : chevronDownCircle
                          }
                        ></IonIcon>
                      </IonCol>
                    </IonCol>
                    {vehicleShowCircles && (
                      <IonRow className="circlesCont">
                        {dataArray
                          ?.filter((data) => {
                            const { gCode, mCode, vCode } = data;
                            return (
                              gCode === result.g_id &&
                              mCode === result.m_id &&
                              vCode === result.v_id
                            );
                          })
                          .map((filteredData, index) => {
                            return (
                              <>
                                {((Number(filteredData.serviceChecks) /
                                  Number(filteredData.serviceChecks_Max)) *
                                  100 ?? 0) > 79 ? (
                                  <IonCol
                                    size="12"
                                    className="completenesLabels"
                                  >
                                    <span className="greenData">
                                      <IonImg
                                        src={icons.serviceChecks}
                                        alt="loading"
                                      />
                                    </span>
                                    <p> Service checks data available</p>
                                  </IonCol>
                                ) : (
                                  <IonCol
                                    size="12"
                                    className="completenesLabels"
                                  >
                                    <span className="redData">
                                      <IonImg
                                        src={icons.serviceChecks}
                                        alt="loading"
                                      />
                                    </span>
                                    <p> Service checks data unavailable</p>
                                  </IonCol>
                                )}

                                {((Number(filteredData.serviceLabour) /
                                  Number(filteredData.serviceLabour_Max)) *
                                  100 ?? 0) > 79 ? (
                                  <IonCol
                                    size="12"
                                    className="completenesLabels"
                                  >
                                    <span className="greenData">
                                      <IonImg
                                        src={icons.serviceLabour}
                                        alt="loading"
                                      />
                                    </span>
                                    <p> Service labour data available</p>
                                  </IonCol>
                                ) : (
                                  <IonCol
                                    size="12"
                                    className="completenesLabels"
                                  >
                                    <span className="redData">
                                      <IonImg
                                        src={icons.serviceLabour}
                                        alt="loading"
                                      />
                                    </span>
                                    <p> Service labour data unavailable</p>
                                  </IonCol>
                                )}

                                {((Number(filteredData.serviceLubes) /
                                  Number(filteredData.serviceLubes_Max)) *
                                  100 ?? 0) > 79 ? (
                                  <IonCol
                                    size="12"
                                    className="completenesLabels"
                                  >
                                    <span className="greenData">
                                      <IonImg
                                        src={icons.serviceLube}
                                        alt="loading"
                                      />
                                    </span>
                                    <p> Fluids data available</p>
                                  </IonCol>
                                ) : (
                                  <IonCol
                                    size="12"
                                    className="completenesLabels"
                                  >
                                    <span className="redData">
                                      <IonImg
                                        src={icons.serviceLube}
                                        alt="loading"
                                      />
                                    </span>
                                    <p> Fluids data unavailable</p>
                                  </IonCol>
                                )}

                                {((Number(filteredData.serviceParts) /
                                  Number(filteredData.serviceParts_Max)) *
                                  100 ?? 0) > 79 ? (
                                  <IonCol
                                    size="12"
                                    className="completenesLabels"
                                  >
                                    <span className="greenData">
                                      <IonImg
                                        src={icons.serviceChecks}
                                        alt="loading"
                                      />
                                    </span>
                                    <p> Service parts data available</p>
                                  </IonCol>
                                ) : (
                                  <IonCol
                                    size="12"
                                    className="completenesLabels"
                                  >
                                    <span className="redData">
                                      <IonImg
                                        src={icons.serviceChecks}
                                        alt="loading"
                                      />
                                    </span>
                                    <p> Service parts data unavailable.</p>
                                  </IonCol>
                                )}

                                {((Number(filteredData.serviceParts) /
                                  Number(filteredData.serviceParts_Max)) *
                                  100 ?? 0) > 79 ? (
                                  <IonCol
                                    size="12"
                                    className="completenesLabels"
                                  >
                                    <span className="greenData">
                                      <IonImg
                                        src={icons.maintenanceChecks}
                                        alt="loading"
                                      />
                                    </span>
                                    <p>Replacement parts data available</p>
                                  </IonCol>
                                ) : (
                                  <IonCol
                                    size="12"
                                    className="completenesLabels"
                                  >
                                    <span className="redData">
                                      <IonImg
                                        src={icons.maintenanceChecks}
                                        alt="loading"
                                      />
                                    </span>
                                    <p> Replacement parts data unavailable</p>
                                  </IonCol>
                                )}

                                {((Number(filteredData.serviceParts) /
                                  Number(filteredData.serviceParts_Max)) *
                                  100 ?? 0) > 79 ? (
                                  <IonCol
                                    size="12"
                                    className="completenesLabels"
                                  >
                                    <span className="greenData">
                                      <IonImg
                                        src={icons.maintenanceLabour}
                                        alt="loading"
                                      />
                                    </span>
                                    <p> Maintenance labour data available</p>
                                  </IonCol>
                                ) : (
                                  <IonCol
                                    size="12"
                                    className="completenesLabels"
                                  >
                                    <span className="redData">
                                      <IonImg
                                        src={icons.maintenanceLabour}
                                        alt="loading"
                                      />
                                    </span>
                                    <p> Maintenance labour data unavailable</p>
                                  </IonCol>
                                )}

                                {((Number(filteredData.serviceParts) /
                                  Number(filteredData.serviceParts_Max)) *
                                  100 ?? 0) > 79 ? (
                                  <IonCol
                                    size="12"
                                    className="completenesLabels"
                                  >
                                    <span className="greenData">
                                      <IonImg
                                        src={icons.maintenanceParts}
                                        alt="loading"
                                      />
                                    </span>
                                    <p> Maintenance parts data available.</p>
                                  </IonCol>
                                ) : (
                                  <IonCol
                                    size="12"
                                    className="completenesLabels"
                                  >
                                    <span className="redData">
                                      <IonImg
                                        src={icons.maintenanceParts}
                                        alt="loading"
                                      />
                                    </span>
                                    <p> Maintenance parts data unavailable.</p>
                                  </IonCol>
                                )}
                              </>
                            );
                          })}
                      </IonRow>
                    )}
                    <IonCol size="12" className="timerCont">
                      <CountdownTimer
                        onSelectVehicleGarageLimited={
                          onSelectVehicleGarageLimited
                        }
                        endDate={result.end_date}
                        AddToCart={HandleAddToCart}
                      />
                    </IonCol>
                  </div>
                </>
              );
            })}
          </div>
        </GlobalGrid>
      ) : (
        <GlobalGrid
          currentPageTitle="MY GARAGE"
          closeCurrentPage={() => navigateToHome()}
        >
          <IonRow className="garageRow">
            {sessionState.user?.username !== "GUEST" &&
              garageData
                ?.sort((a, b) => {
                  const makeA = a.result[0].make.toLowerCase();
                  const makeB = b.result[0].make.toLowerCase();
                  if (makeA < makeB) return -1;
                  if (makeA > makeB) return 1;
                  return 0;
                })
                .map((result, index) => {
                  const { groupCode, modelCode, variantCode } =
                    result.result[0];
                  const vehicleShowCircles = showCircles[index] || false;
                  const onSelectVehicleGarageLimited = () => {
                    onSelectVehicleGarageLimeted(result);
                  };
                  const HandleAddToCart = () => {
                    HandleAddToCarts(result.result, index);
                    onRemoveToCartVehicle(result);
                  };
                  const filteredDate = garageDate?.filter(
                    (data) =>
                      data.g_id === groupCode &&
                      data.m_id === modelCode &&
                      data.v_id === variantCode
                  );
                  return (
                    <IonCol
                      key={"resultItem" + index}
                      size="11.5"
                      sizeXs="11.5"
                      sizeMd="11.5"
                      sizeLg="12"
                      sizeXl="3.8"
                      id="sliderCol"
                    >
                      <IonItemSliding>
                        <IonItemOptions side="end">
                          <IonItemOption
                            onClick={() => {
                              onDeleteVehicle(result);
                            }}
                          >
                            <IonIcon
                              slot="start"
                              className="delete-txt"
                              icon={trash}
                            />
                            <br />
                            <span className="delete-txt"> Delete</span>
                          </IonItemOption>
                        </IonItemOptions>

                        <IonItem>
                          <div className="visitorCard">
                            <IonCol size="12" className="vistorImgCont">
                              {filteredDate?.map((filteredItem, idx) => {
                                const endDatePassed =
                                  new Date(filteredItem.end_date) < new Date();
                                return (
                                  <IonCol size="6" id="img">
                                    <img
                                      onClick={() => {
                                        if (!endDatePassed) {
                                          onSelectVehicleGarage(result);
                                          onSelectVehicle(result);
                                        }
                                      }}
                                      src={getImage(
                                        groupCode.toString(),
                                        modelCode.toString(),
                                        variantCode.toString()
                                      )}
                                      alt=""
                                    />
                                  </IonCol>
                                );
                              })}
                              <IonCol size="6" id="details">
                                <IonCol size="12" id="row">
                                  {result.result[0].make}
                                </IonCol>
                                <IonCol size="12" id="row">
                                  {result.result[0].model}
                                </IonCol>
                                <IonCol size="12" id="row">
                                  {result.result[0].variant}
                                </IonCol>
                                <IonCol size="12" id="row">
                                  {" "}
                                  G{groupCode}M{modelCode}V{variantCode}
                                </IonCol>
                              </IonCol>
                            </IonCol>
                            <IonCol size="12" className="comprehensiveDropDown">
                              <IonCol size="11">
                                Comprehensive data available
                              </IonCol>
                              <IonCol size="1">
                                <IonIcon
                                  id="dropDownToggler"
                                  onClick={() => {
                                    const updatedShowCircles = [...showCircles];
                                    updatedShowCircles[index] =
                                      !vehicleShowCircles;
                                    setShowCircles(updatedShowCircles);
                                    setSelectedVehicleIndex(index);
                                  }}
                                  className="circle-up-down"
                                  src={
                                    vehicleShowCircles
                                      ? chevronUpCircle
                                      : chevronDownCircle
                                  }
                                ></IonIcon>
                              </IonCol>
                            </IonCol>
                            {vehicleShowCircles && (
                              <IonRow className="circlesCont">
                                {dataArray
                                  ?.filter((data) => {
                                    const { gCode, mCode, vCode } = data;
                                    return (
                                      gCode === result.result[0].groupCode &&
                                      mCode === result.result[0].modelCode &&
                                      vCode === result.result[0].variantCode
                                    );
                                  })
                                  .map((filteredData, index) => {
                                    return (
                                      <>
                                        {((Number(filteredData.serviceChecks) /
                                          Number(
                                            filteredData.serviceChecks_Max
                                          )) *
                                          100 ?? 0) > 79 ? (
                                          <IonCol
                                            size="12"
                                            className="completenesLabels"
                                          >
                                            <span className="greenData">
                                              <IonImg
                                                src={icons.serviceChecks}
                                                alt="loading"
                                              />
                                            </span>
                                            <p>
                                              {" "}
                                              Service checks data available
                                            </p>
                                          </IonCol>
                                        ) : (
                                          <IonCol
                                            size="12"
                                            className="completenesLabels"
                                          >
                                            <span className="redData">
                                              <IonImg
                                                src={icons.serviceChecks}
                                                alt="loading"
                                              />
                                            </span>
                                            <p>
                                              {" "}
                                              Service checks data unavailable
                                            </p>
                                          </IonCol>
                                        )}

                                        {((Number(filteredData.serviceLabour) /
                                          Number(
                                            filteredData.serviceLabour_Max
                                          )) *
                                          100 ?? 0) > 79 ? (
                                          <IonCol
                                            size="12"
                                            className="completenesLabels"
                                          >
                                            <span className="greenData">
                                              <IonImg
                                                src={icons.serviceLabour}
                                                alt="loading"
                                              />
                                            </span>
                                            <p>
                                              {" "}
                                              Service labour data available
                                            </p>
                                          </IonCol>
                                        ) : (
                                          <IonCol
                                            size="12"
                                            className="completenesLabels"
                                          >
                                            <span className="redData">
                                              <IonImg
                                                src={icons.serviceLabour}
                                                alt="loading"
                                              />
                                            </span>
                                            <p>
                                              {" "}
                                              Service labour data unavailable
                                            </p>
                                          </IonCol>
                                        )}

                                        {((Number(filteredData.serviceLubes) /
                                          Number(
                                            filteredData.serviceLubes_Max
                                          )) *
                                          100 ?? 0) > 79 ? (
                                          <IonCol
                                            size="12"
                                            className="completenesLabels"
                                          >
                                            <span className="greenData">
                                              <IonImg
                                                src={icons.serviceLube}
                                                alt="loading"
                                              />
                                            </span>
                                            <p> Fluids data available</p>
                                          </IonCol>
                                        ) : (
                                          <IonCol
                                            size="12"
                                            className="completenesLabels"
                                          >
                                            <span className="redData">
                                              <IonImg
                                                src={icons.serviceLube}
                                                alt="loading"
                                              />
                                            </span>
                                            <p> Fluids data unavailable</p>
                                          </IonCol>
                                        )}

                                        {((Number(filteredData.serviceParts) /
                                          Number(
                                            filteredData.serviceParts_Max
                                          )) *
                                          100 ?? 0) > 79 ? (
                                          <IonCol
                                            size="12"
                                            className="completenesLabels"
                                          >
                                            <span className="greenData">
                                              <IonImg
                                                src={icons.serviceChecks}
                                                alt="loading"
                                              />
                                            </span>
                                            <p> Service parts data available</p>
                                          </IonCol>
                                        ) : (
                                          <IonCol
                                            size="12"
                                            className="completenesLabels"
                                          >
                                            <span className="redData">
                                              <IonImg
                                                src={icons.serviceChecks}
                                                alt="loading"
                                              />
                                            </span>
                                            <p>
                                              {" "}
                                              Service parts data unavailable.
                                            </p>
                                          </IonCol>
                                        )}

                                        {((Number(filteredData.serviceParts) /
                                          Number(
                                            filteredData.serviceParts_Max
                                          )) *
                                          100 ?? 0) > 79 ? (
                                          <IonCol
                                            size="12"
                                            className="completenesLabels"
                                          >
                                            <span className="greenData">
                                              <IonImg
                                                src={icons.maintenanceChecks}
                                                alt="loading"
                                              />
                                            </span>
                                            <p>
                                              Replacement parts data available
                                            </p>
                                          </IonCol>
                                        ) : (
                                          <IonCol
                                            size="12"
                                            className="completenesLabels"
                                          >
                                            <span className="redData">
                                              <IonImg
                                                src={icons.maintenanceChecks}
                                                alt="loading"
                                              />
                                            </span>
                                            <p>
                                              {" "}
                                              Replacement parts data unavailable
                                            </p>
                                          </IonCol>
                                        )}

                                        {((Number(filteredData.serviceParts) /
                                          Number(
                                            filteredData.serviceParts_Max
                                          )) *
                                          100 ?? 0) > 79 ? (
                                          <IonCol
                                            size="12"
                                            className="completenesLabels"
                                          >
                                            <span className="greenData">
                                              <IonImg
                                                src={icons.maintenanceLabour}
                                                alt="loading"
                                              />
                                            </span>
                                            <p>
                                              {" "}
                                              Maintenance labour data available
                                            </p>
                                          </IonCol>
                                        ) : (
                                          <IonCol
                                            size="12"
                                            className="completenesLabels"
                                          >
                                            <span className="redData">
                                              <IonImg
                                                src={icons.maintenanceLabour}
                                                alt="loading"
                                              />
                                            </span>
                                            <p>
                                              {" "}
                                              Maintenance labour data
                                              unavailable
                                            </p>
                                          </IonCol>
                                        )}

                                        {((Number(filteredData.serviceParts) /
                                          Number(
                                            filteredData.serviceParts_Max
                                          )) *
                                          100 ?? 0) > 79 ? (
                                          <IonCol
                                            size="12"
                                            className="completenesLabels"
                                          >
                                            <span className="greenData">
                                              <IonImg
                                                src={icons.maintenanceParts}
                                                alt="loading"
                                              />
                                            </span>
                                            <p>
                                              {" "}
                                              Maintenance parts data available.
                                            </p>
                                          </IonCol>
                                        ) : (
                                          <IonCol
                                            size="12"
                                            className="completenesLabels"
                                          >
                                            <span className="redData">
                                              <IonImg
                                                src={icons.maintenanceParts}
                                                alt="loading"
                                              />
                                            </span>
                                            <p>
                                              {" "}
                                              Maintenance parts data
                                              unavailable.
                                            </p>
                                          </IonCol>
                                        )}
                                      </>
                                    );
                                  })}
                              </IonRow>
                            )}
                            <IonCol size="12" className="timerCont">
                              {filteredDate?.map((filteredItem, idx) => (
                                <>
                                  <IonCol key={idx}>
                                    <CountdownTimer
                                      key={idx}
                                      endDate={filteredItem.end_date}
                                      onSelectVehicleGarageLimited={
                                        onSelectVehicleGarageLimited
                                      }
                                      AddToCart={HandleAddToCart}
                                    />
                                  </IonCol>
                                </>
                              ))}
                            </IonCol>
                          </div>
                        </IonItem>
                      </IonItemSliding>
                    </IonCol>
                  );
                })}
          </IonRow>
          {showVisitor && <div className="visitorParkingLot"></div>}
        </GlobalGrid>
      )}

      <div
        className="visitorsBanner"
        onClick={() => {
          setShowVisitor(!showVisitor);
          setPrevSeen(prevSeen + 1);
        }}
      >
        <div className="visitorText">
          VISITORS PARKING{" "}
          <span id="showVisitorIcon">
            <IonIcon
              icon={showVisitor == false ? caretUpOutline : caretDownOutline}
            ></IonIcon>
          </span>
        </div>
      </div>
    </IonPage>
  );
};

export default Garage;
