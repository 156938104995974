export const images = {
  logo: require("./mobile/images/logo.png"),
  placeholder: require("./mobile/images/placeholder.png"),
  scanButton: require("./mobile/images/scan-button.png"),
  info: require("./mobile/images/info.png"),
  infoIcon: require("./mobile/images/info-icon.png"),
  rates10k: require("./mobile/images/10krates.png"),
  competitors: require("./mobile/images/competitors.png"),
  detailedSpecs: require("./mobile/images/detailed-specs.png"),
  financeCalculator: require("./mobile/images/finance-calculator.png"),
  maintenanceCosts: require("./mobile/images/maintenance-costs.png"),
  partsBasket: require("./mobile/images/parts-basket.png"),
  residualValue: require("./mobile/images/residual-value.png"),
  reviews: require("./mobile/images/reviews.png"),
  serviceCosts: require("./mobile/images/service-costs.png"),
  tco: require("./mobile/images/tco.png"),
  orangeQuestion: require("./mobile/images/orange-question.png"),
  saflag: require("./mobile/images/saflag.png"),
  aboutmapo: require("./mobile/images/aboutmapo.png"),
  contact: require("./mobile/images/contact.png"),
  modelSearch: require("./mobile/images/model_search.png"),
  myGarage: require("./mobile/images/my_garage.png"),
  myProfile: require("./mobile/images/my_profile.png"),
  support: require("./mobile/images/support.png"),
  tutorial: require("./mobile/images/tutorial.png"),
  downloadPdf: require("./mobile/images/downloadpdf.png"),
  oem: require("./mobile/images/oem.png"),
  api: require("./mobile/images/api.png"),
  app: require("./mobile/images/app.png"),
  fleet: require("./mobile/images/fleet.png"),
  insurance: require("./mobile/images/insurance.png"),
  sales: require("./mobile/images/sales.png"),
  filter: require("./mobile/images/filter.png"),
  agri: require("./mobile/images/agri.png"),
  air: require("./mobile/images/air.png"),
  bikes: require("./mobile/images/bikes.png"),
  commercial: require("./mobile/images/commercial.png"),
  forestry: require("./mobile/images/forestry.png"),
  mining: require("./mobile/images/mining.png"),
  passenger: require("./mobile/images/passenger.png"),
  chevronblack: require("./mobile/images/chevronblack.png"),
  emailicon: require("./mobile/images/emailicon.png"),
  mobileicon: require("./mobile/images/mobileicon.png"),
  changecars: require("./mobile/images/changecars.png"),
  modeltestIcon: require("./mobile/images/ModelTestIcon.png"),
  XtwitterIcon: require("./mobile/images/Xtwitter.png"),
  WhatsApp: require("./mobile/images/whatsapp.png"),
  approvalStamp: require("./mobile/images/greenApproval.png"),
  blueApproval: require("./mobile/images/greyApproval.png"),
  visitorsBanner: require("./mobile/images/visitorsBanner.png"),
};

export const desktopImages = {
  logo: require("./desktop/images/desktop-bg.png"),
  chart: require("./desktop/images/chart.png"),
  table: require("./desktop/images/table.png"),
  youtube: require("./desktop/images/youtube.png"),
  pdf: require("./desktop/images/pdf.png"),
  swapIcon: require("./desktop/images/swap-icon.png"),
  manualSearch: require("./desktop/images/manual-search.png"),
  customizeLarge: require("./desktop/images/customizelarge.png"),
  pdfLarge: require("./desktop/images/pdflarge.png"),
  dataIcon1: require("./desktop/images/dataicon1.png"),
  dataIcon2: require("./desktop/images/dataicon2.png"),
  dataIcon3: require("./desktop/images/dataicon3.png"),
  dataIcon4: require("./desktop/images/dataicon4.png"),
  dataIcon5: require("./desktop/images/dataicon5.png"),
  dataIcon6: require("./desktop/images/dataicon6.png"),
  dataIcon7: require("./desktop/images/dataicon7.png"),
};

export const svg = {
  modelSearch: require("./svg/model-search.svg"),
  myProfile: require("./svg/my-profile.svg"),
  appTutorial: require("./svg/app-tutorial.svg"),
  info: require("./svg/info.svg"),
  contact: require("./svg/contact.svg"),
  scan: require("./svg/scan.svg"),
  garage: require("./svg/garage.svg"),
};

export const icons = {
  appGarageBlack : require("./icons/App_icon_garage 3.png"),
  appTargetAudienceBlack: require("./icons/App_icon_Profile 3.png"),
  appYoutubeBlack: require("./icons/App_icon_Youtube.png"),
  appInstallBlack: require("./icons/App_icon_Install 3.png"),
  appInfoBlack: require("./icons/App_icon_Info 3.png"),
  rates10k: require("./icons/10krates.png"),
  competitorAnalysis: require("./icons/competitor-analysis.png"),
  customizeReport: require("./icons/customize-report.png"),
  depreciation: require("./icons/depreciation.png"),
  maintenanceCosts: require("./icons/maintenance-costs.png"),
  modelSelection: require("./icons/model-selection.png"),
  sendReport: require("./icons/send-report.png"),
  serviceCosts: require("./icons/service-costs.png"),
  specifications: require("./icons/specifications.png"),
  support: require("./icons/support.png"),
  totalCostAnalysis: require("./icons/total-cost-analysis.png"),
  training: require("./icons/training.png"),
  userProfileIcon: require("./icons/userProfileICon.png"),
  myGarageIcon: require("./icons/myGarageIcon.png"),
  mobileSearchIcon: require("./icons/mobileSearchIcon.png"),
  appTutorialIcon: require("./icons/appTutorialIcon.png"),
  appSupportIcon: require("./icons/appSupportIcon.png"),
  appShareIcon: require("./icons/appShareIcon.png"),
  AppShare: require("./icons/appShare.png"),
  appLeaveIcon: require("./icons/logoutIcon.png"),
  appContactIcon: require("./icons/contact.png"),
  mobileMapoIcon: require("./icons/MAPOICONs.png"),
  mobileInfoIcon: require("./icons/infoIcon.png"),
  GarageHeader: require("./icons/GarageHeader.png"),
  WhiteCartIcon: require("./mobile/images/WhiteIconCart(500x500) 1.png"),
  WhiteIconMenu: require("./mobile/images/WhiteIconMenu(500x500) 1.png"),
  serviceIcon: require("./mobile/images/service.png"),
  serviceChecks: require("./mobile/images/service checks.png"),
  serviceLabour: require("./mobile/images/service labour.png"),
  serviceLube: require("./mobile/images/service fluids.png"),
  serviceParts: require("./mobile/images/service.png"),
  maintenanceChecks: require("./mobile/images/maintenance checks.png"),
  maintenanceLabour: require("./mobile/images/maintenance labour.png"),
  maintenanceParts: require("./mobile/images/maintenance.png"),
  ShareMapoBarCode: require("./icons/MAPO_South_Africa_Mobile_App.png"),
  ShareContactUs: require("./icons/MAPO_South_Africa_Contact.png"),
  VistorsParking: require("./icons/vistors-parking-Icon.png"),
  blueSpeaker: require("./icons/blueSpeaker.png"),
  ourOfferingArrow: require("./icons/whatWeOfferArrow.png"),
  myGarageSearch: require("../assets/icons/Search_options_My Garage (500x500).png"),
  filterSearch: require("../assets/icons/Search_options_Filter (500x500).png"),
  vinSearch: require("../assets/icons/Search_options_VINScan (500x500).png"),
  voiceRec: require("../assets/icons/Search_options_Voice (500x500).png"),
  newShare: require("../assets/icons/Scan_QR.png"),
  mnewWhatsApp: require("../assets/icons/newWhatsapp.png"),
  newLinkedin: require("../assets/icons/newLinkedin.png"),
  newMail: require("../assets/icons/newMail.png"),
  newFacebook: require("../assets/icons/newFacebookj.png"),
  newTwittere: require("../assets/icons/newTwitter.png"),
  newAlertIcons: require("../assets/icons/Alert_Icon.png"),
};

export const PartsBasketIcons = {
  Wheels: require("./icons/PartsBasketIcon/Parts Basket Icon (Wheels).png"),
  Transmission: require("./icons/PartsBasketIcon/Parts Basket Icon (Transmission).png"),
  Suspension: require("./icons/PartsBasketIcon/Parts Basket Icon (Suspension).png"),
  Steering: require("./icons/PartsBasketIcon/Parts Basket Icon (Steering).png"),
  Safety: require("./icons/PartsBasketIcon/Parts Basket Icon (Safety).png"),
  Lubricants: require("./icons/PartsBasketIcon/Parts Basket Icon (Lubricants).png"),
  Interior: require("./icons/PartsBasketIcon/Parts Basket Icon (Interior).png"),
  Glass: require("./icons/PartsBasketIcon/Parts Basket Icon (Glass).png"),
  Fuel: require("./icons/PartsBasketIcon/Parts Basket Icon (Fuel).png"),
  Exhaust: require("./icons/PartsBasketIcon/Parts Basket Icon (Exhaust).png"),
  Engine: require("./icons/PartsBasketIcon/Parts Basket Icon (Engine).png"),
  Electrical: require("./icons/PartsBasketIcon/Parts Basket Icon (Electrical).png"),
  DriveTrain: require("./icons/PartsBasketIcon/Parts Basket Icon (Drivetrain).png"),
  Brakes: require("./icons/PartsBasketIcon/Parts Basket Icon (Brakes).png"),
  Body: require("./icons/PartsBasketIcon/Parts Basket Icon (Body).png"),
  Cooling: require("./icons/PartsBasketIcon/Parts Basket Icon (cooling).png"),
  Accessories: require("./icons/PartsBasketIcon/Parts Basket Icon (Accessories).png"),
};
