import { IonCol, IonContent, IonPage, IonRow, IonIcon } from "@ionic/react";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  logOut,
  menuOutline,
  personCircle,
  locationOutline,
  location,
  chevronBackCircleSharp,
} from "ionicons/icons";
import DesktopContainer from "../../components/desktop-container";
import DesktopHeader from "../../components/desktop-header";
import Header from "../../components/header";
import Loading from "../../components/loading";
import { images } from "../../assets";
import SideMenu from "../../components/side-menu";
import SubPageHeading from "../../components/sub-page-heading";
import { AppScreens } from "../../enums";
import { RootState } from "../../redux/store";
import {
  setCompetitors,
  setCustomReportParams,
  setLabourRatesData,
  setLargeHeader,
  setScreen,
  setSelectedVehicle,
} from "../../redux/ui/uiSlice";
import { Config } from "../../Config";
import { Oem } from "../../interfaces";
import { setSelectedOem } from "../../redux/session/sessionSlice";
import axios from "axios";
import Button from "../../components/button";
import { LabourRates } from "../../api/mapo";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { Plugins } from "@capacitor/core";
import { useHistory } from "react-router-dom";
import GlobalGrid from "../../components/globalGridMain/globalGrid";
import "./userProfile.css";

const { Storage } = Plugins;

const UserProfile: React.FC = () => {
  const uiState = useSelector((state: RootState) => state.ui);
  const sessionState = useSelector((state: RootState) => state.session);
  const dispatch = useDispatch();
  const history = useHistory();

  const onBackPress = useCallback(() => {
    //dispatch(setScreen(AppScreens.Home));
    history.push("/home");
  }, [dispatch]);

  const [state, setState] = useState({
    ip: "",
    countryName: "",
    countryCode: "",
    city: "",
    timezone: "",
  });

  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setState({
          ...state,
          ip: data.ip,
          countryName: data.country_name,
          countryCode: data.country_calling_code,
          city: data.city,
          timezone: data.timezone,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [photo, setPhoto] = useState<string | undefined>(undefined);

  useEffect(() => {
    // Load image from local storage on component mount
    getGeoInfo();
    loadStoredPhoto();
  }, []);

  const loadStoredPhoto = async () => {
    const storedPhoto = await Storage.get({ key: "capturedPhoto" });
    if (storedPhoto.value) {
      setPhoto(storedPhoto.value);
    }
  };

  const takePhoto = async () => {
    try {
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
      });

      setPhoto(image.webPath);

      // Save image in local storage
      await Storage.set({
        key: "capturedPhoto",
        value: image.webPath!,
      });
    } catch (error) {
      console.error("Error taking photo", error);
    }
  };

  const onNewSearch = useCallback(() => {
    // dispatch(setScreen(AppScreens.Home));
    history.push("/home");
  }, [dispatch]);

  const onViewMyGarage = useCallback(() => {
    dispatch(setLargeHeader(false));
    // dispatch(setScreen(AppScreens.Garage));
    history.push("/Garage");
    LabourRates().then((LabourRatesResponse) => {
      dispatch(setLabourRatesData(LabourRatesResponse.data?.result));
    });
  }, [dispatch]);

  return (
    <GlobalGrid currentPageTitle="MY PROFILE">
      <IonRow>
        <IonCol size="12" className="ProfileImg">
          <IonCol size="4" className="img">
            {photo ? (
              <img
                src={photo}
                alt="Captured Photo"
                //onClick={takePhoto}
                className="pic"
              />
            ) : (
              <IonIcon src={personCircle} className="pic"></IonIcon>
            )}
          </IonCol>
          <IonCol size="8" className="userDetails">
            <IonCol size="12" id="email">
              <strong>Email:</strong> {sessionState.user?.username}
            </IonCol>
            <IonCol size="12" id="email">
              <strong>
                <IonIcon
                  src={locationOutline}
                  className="LocationIcon"
                ></IonIcon>{" "}
              </strong>
              {state.city}
            </IonCol>
          </IonCol>
        </IonCol>
      </IonRow>
    </GlobalGrid>
  );
};

export default UserProfile;
